import React, { useState } from "react";
import Logo from "../../Assets/blue_logo.png";
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import { addItem, updateItem } from "../../store/slice";
import { ahSelector } from "../../store/slice";

import { date, invoice, sumArray } from "./utils";

import jsPDF from "jspdf";
import { BottomTable, FormTable, MiddleTable, PDFTable, TopTable } from "./Table";
import PDFGenerator from "./PDFGenerator";
import { AddForm, CustomerForm } from "./Form";

const Invoice = () => {
	const dispatch = useDispatch();
	const items = useSelector(ahSelector.selectEntities);
	const count = useSelector(ahSelector.selectTotal);
	const dueAmount = useSelector((state) => state.due).due;
	const [item, setItem] = useState({
		id: null,
		description: "",
		rate: "",
		quantity: "",
	});
	const [customer, setCustomer] = useState({
		name: "",
		email: "",
		address: "",
		company: "",
	});
	const [edit, setEdit] = useState(false);
	const [paid, setPaid] = useState(0);
	const [alert, setAlert] = useState("");
	const totalPrice = sumArray(items);

	const InputEvent = (event) => {
		const { name, value } = event.target;

		setItem((oldValue) => {
			return { ...oldValue, [name]: value };
		});
	};
	const InputEventCustomer = (event) => {
		const { name, value } = event.target;

		setCustomer((oldValue) => {
			return { ...oldValue, [name]: value };
		});
	};

	const submitForm = (e) => {
		e.preventDefault();
		if (item.description && item.rate && item.quantity) {
			const itemTotal = item.rate * item.quantity;
			dispatch(
				addItem({
					id: count + 1,
					description: item.description,
					rate: item.rate,
					quantity: item.quantity,
					price: itemTotal,
				})
			);
		}
		setItem({
			id: null,
			description: "",
			rate: "",
			quantity: "",
		});
	};
	const editItem = (id, desc, rate, quantity) => {
		setItem({
			id: id,
			description: desc,
			rate: rate,
			quantity: quantity,
		});
		setEdit(true);
	};

	const updateForm = (e) => {
		e.preventDefault();
		const itemTotal = item.rate * item.quantity;
		dispatch(
			updateItem({
				id: item.id,
				changes: {
					description: item.description,
					rate: item.rate,
					quantity: item.quantity,
					price: itemTotal,
				},
			})
		);
		setItem({
			id: null,
			description: "",
			rate: "",
			quantity: "",
		});
		setEdit(false);
	};

	let itemsList = [];
	let pdfItemsList = [];
	for (const id in items) {
		if (Object.hasOwnProperty.call(items, id)) {
			const element = items[id];
			pdfItemsList.push(<PDFTable element={element} />);
			itemsList.push(<FormTable element={element} editItem={editItem} />);
		}
	}

	const generatePDF = () => {
		const doc = new jsPDF("portrait", "pt", "a4");
		if (!customer.name && !customer.email && !customer.company) {
			setAlert("Please fill the Customer Details");
		} else if (pdfItemsList.length === 0) {
			setAlert("Please add at least one item");
		} else if (!paid) {
			setAlert("Please fill the Paid Amount");
		} else {
			setAlert("");
			doc.html(document.getElementById("content"), {
				callback: function (pdf) {
					// doc.output("dataurlnewwindow");
					pdf.save(`${invoice}.pdf`);
				},
				x: 10,
				y: 10,
			});
		}
	};

	return (
		<>
			<Helmet>
				<title>Automations Hub | Invoice</title>
				<meta name="description" content="Invoice" />
			</Helmet>
			<div className="container ah-container my-4">
				<div className="invoice-heading">
					<h1 className="py-2">Invoice</h1>
				</div>
				<div className="row my-4 px-2 invoice-logo justify-content-center">
					<img src={Logo} alt="Logo" />
				</div>
				<div className="row my-4 px-2">
					<CustomerForm customer={customer} InputEventCustomer={InputEventCustomer} />
					<TopTable invoice={invoice} date={date} due={totalPrice - dueAmount} />
				</div>
				<div className="row px-2 my-3">
					<MiddleTable itemsList={itemsList} />
					<AddForm item={item} InputEvent={InputEvent} edit={edit} submitForm={submitForm} updateForm={updateForm} />
					<BottomTable totalPrice={totalPrice} paid={paid} setPaid={setPaid} />
				</div>
				<div className="download-section">
					{alert ? <h6 className="text-danger text-center py-2">{alert}</h6> : null}
					<div className="invoice-pay-now">
						<button className="py-2" onClick={generatePDF}>
							Download PDF
						</button>
					</div>
				</div>
			</div>

			<PDFGenerator Logo={Logo} customer={customer} invoice={invoice} date={date} due={totalPrice} pdfItemsList={pdfItemsList} totalPrice={totalPrice} paid={paid} />
		</>
	);
};

export default Invoice;
