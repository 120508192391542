import React from "react";
import About from "../Components/About";
import Explore from "../Components/Explore";
import Footer from "../Components/Footer";
import HomePage from "../Components/HomePage";
import Service from "../Components/Service";
import WhatWeDo from "../Components/WhatWeDo";
import { Helmet } from "react-helmet";

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Automations Hub | Home</title>
                <meta name="description" content="Home Page" />
            </Helmet>
            <HomePage />
            <About />
            <Explore />
            <Service />
            <WhatWeDo />
            <Footer />
        </>
    );
};

export default Home;
