import React from "react";
import ListAltIcon from "@material-ui/icons/ListAlt";
import StorageIcon from "@material-ui/icons/Storage";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";

const Service = () => {
    return (
        <>
            <section
                className="section-service"
                data-aos="zoom-in-up"
                data-aos-delay="50"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
            >
                <div className="container ah-container mb-3 pb-5">
                    <div className="row">
                        <div className="col-md-4 col-12 mx-auto ah-about">
                            <div className="service-details">
                                <h1>
                                    <ListAltIcon
                                        style={{ color: "#25c3dd" }}
                                        fontSize="large"
                                    />
                                </h1>
                                <h3 className="ah-btn service-heading">
                                    Excel Spreadsheets
                                </h3>
                                <p>
                                    Microsoft Excel is a spread sheet program
                                    used to store and retrieve numerical data in
                                    a grid format of columns and rows. Excel is
                                    ideal for entering, calculating and
                                    analysing company data such as sales
                                    figures, sales taxes or commissions.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mx-auto ah-about">
                            <div className="service-details">
                                <h1>
                                    <StorageIcon
                                        style={{ color: "#25c3dd" }}
                                        fontSize="large"
                                    />
                                </h1>
                                <h3 className="ah-btn service-heading">
                                    Access Databases
                                </h3>
                                <p>
                                    Microsoft Access is a flexible and
                                    relatively simple way to create databases to
                                    store, manage and enter data. It helps you
                                    analyse large amounts of information, and
                                    manage related data more efficiently than
                                    Microsoft Excel or other spread sheet
                                    applications.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mx-auto ah-about">
                            <Link to="/work" exact className="services-link">
                                <div className="service-details">
                                    <h1>
                                        <EditIcon
                                            style={{ color: "#25c3dd" }}
                                            fontSize="large"
                                        />
                                    </h1>
                                    <h3 className="ah-btn service-heading">
                                        Google Sheets
                                    </h3>
                                    <p>
                                        Create, Edit and Share spread sheets
                                        from your phone or tablet no dedicated
                                        software needed. Handle everything from
                                        simple task lists to data analysis with
                                        charts, filters and pivot tables, no
                                        matter where you happen to be. you can
                                        Work in Sheets even when youre offline.
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Service;
