import { createSlice } from "@reduxjs/toolkit";

const dueSlice = createSlice({
	name: "due",
	initialState: {
		due: 0,
	},
	reducers: {
		due: (state, action) => {
			state.due = action.payload;
		},
	},
});

export const { due } = dueSlice.actions;
export default dueSlice.reducer;
