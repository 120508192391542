import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../Containers/Home";
import Invoice from "../Containers/Invoice/Invoice";
import NotFound from "../Containers/NotFound";

const Routes = () => {
    return (
        <>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/invoice" component={Invoice} />
                <Route component={NotFound} />
            </Switch>
        </>
    );
};

export default Routes;
