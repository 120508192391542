import { BrowserRouter } from "react-router-dom";
import AHNavbar from "./Components/AHNavbar";
import Routes from "./hocs/Routes";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

function App() {
    return (
        <BrowserRouter>
            <AHNavbar />
            <Routes />
        </BrowserRouter>
    );
}

export default App;
