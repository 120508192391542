const randomNumber = Math.random() * 100000 + 1;
export const invoice = Math.round(randomNumber);

const currentDate = new Date();
export const date = currentDate.toLocaleDateString();

export const sumArray = (items) => {
    let array = [];
    for (const id in items) {
        array.push(items[id].price);
    }
    const sum = array.reduce((a, b) => a + b, 0);
    return sum;
};
