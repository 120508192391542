import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../Assets/blue_logo.png";

const AHNavbar = () => {
    return (
        <>
            <section className="section-navbar">
                <Navbar expand="lg" className="ah-navbar">
                    <div className="container-fluid ah-container-fluid">
                        <Navbar.Brand>
                            <Link to="/">
                                <figure>
                                    <img
                                        src={Logo}
                                        alt="Logo"
                                        className="ah-navbar-brand"
                                    />
                                </figure>
                            </Link>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav ah-nav">
                            <Nav className="ms-auto"></Nav>
                        </Navbar.Collapse>
                    </div>
                </Navbar>
                <div className="blank" style={{ height: "5rem" }} />
            </section>
        </>
    );
};

export default AHNavbar;
