import React from "react";

const PDFGenerator = ({
    Logo,
    customer,
    invoice,
    date,
    due,
    pdfItemsList,
    totalPrice,
    paid,
}) => {
    return (
        <>
            <div className="container" style={{ display: "none" }}>
                <div
                    className="mx-auto"
                    id="content"
                    style={{
                        width: "152mm",
                        boxSizing: "border-box",
                    }}
                >
                    <div className="invoice-heading pdf-width">
                        <h2 className="py-2">Invoice</h2>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <p className="pdf-font-size px-3 my-0">
                                {customer.name}
                            </p>
                            <p className="pdf-font-size px-3 my-0">
                                {customer.email}
                            </p>
                            <p className="pdf-font-size px-3 my-0">
                                {customer.address}
                            </p>
                        </div>
                        <div className="col-6 d-flex justify-content-center mx-auto pe-5 pb-5 pt-3">
                            <img src={Logo} alt="Logo" className="pdf-logo" />
                        </div>
                    </div>
                    <div className="row pdf-width">
                        <div className="col-6 py-2 ps-4 pt-3">
                            <h5 style={{ fontWeight: "bold" }}>
                                {customer.company} <br /> c/o {customer.name}
                            </h5>
                        </div>
                        <div className="col-6">
                            <table className="pdf-invoice-top-table">
                                <tr>
                                    <td className="table-head">
                                        Invoice Number
                                    </td>
                                    <td>{invoice}</td>
                                </tr>
                                <tr>
                                    <td className="table-head">Date</td>
                                    <td>{date}</td>
                                </tr>
                                <tr>
                                    <td className="table-head">Amount Due</td>
                                    <td>${due}</td>
                                </tr>
                            </table>
                        </div>
                        <div
                            className="col-12 mt-4"
                            style={{ paddingLeft: "1.5rem" }}
                        >
                            {pdfItemsList.length > 0 ? (
                                <table className="pdf-invoice-bottom-table my-3">
                                    <tr>
                                        <td className="table-head text-center">
                                            Item
                                        </td>
                                        <td className="table-head text-center pdf-td-description">
                                            Description
                                        </td>
                                        <td className="table-head text-center">
                                            Rate
                                        </td>
                                        <td className="table-head text-center">
                                            Quantity
                                        </td>
                                        <td className="table-head text-center">
                                            Price
                                        </td>
                                    </tr>
                                    {pdfItemsList}
                                </table>
                            ) : (
                                <h1 className="text-center my-3">
                                    NO DATA AVAILABLE
                                </h1>
                            )}
                        </div>
                        <div className="col-12 my-3">
                            <table className="pdf-invoice-end-table">
                                <tr>
                                    <td className="table-head">Total</td>
                                    <td className="text-end">${totalPrice}</td>
                                </tr>
                                <tr>
                                    <td className="table-head">Amount Paid</td>
                                    <td className="text-end">${paid}</td>
                                </tr>
                                <tr>
                                    <td className="table-head">Balance Due</td>
                                    <td className="text-end">
                                        ${totalPrice - paid}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PDFGenerator;
