import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
export const ahAdapter = createEntityAdapter();
export const ahSelector = ahAdapter.getSelectors((state) => state.ahwebsite);

const slice = createSlice({
	name: "ahwebsite",
	initialState: ahAdapter.getInitialState(),
	reducers: {
		addItem: ahAdapter.addOne,
		deleteItem: ahAdapter.removeOne,
		updateItem: ahAdapter.updateOne,
	},
	extraReducers: {
		due: (state, action) => {
			state.due = action.payload;
		},
	},
});

export const { addItem, deleteItem, updateItem } = slice.actions;
export default slice.reducer;
