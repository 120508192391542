import React from "react";
import { Link } from "react-router-dom";
import HomeImage from "../Assets/1.jpg";

const HomePage = () => {
    return (
        <>
            <section className="home-section mb-3 pb-5">
                <figure>
                    <img src={HomeImage} alt="" height="auto" width="100%" />
                </figure>
                <div
                    className="home-description"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="1000"
                >
                    <div className="sub-home-description">
                        <h1>
                            Jumpstart your Business Efficiency with our
                            Automated Solutions using Excel, Access and Google
                            Sheets
                        </h1>
                        <div className="ah-contact">
                            <button className="ah-btn btn">
                                <Link
                                    to="/contact"
                                    exact
                                    className="nav-to-contact"
                                >
                                    Get Free Consulation
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HomePage;
