import React from "react";

import EditIcon from "@material-ui/icons/Edit";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useDispatch } from "react-redux";

import { deleteItem } from "../../store/slice";
import { due } from "../../store/dueSlice";

export const PDFTable = ({ element }) => {
	return (
		<>
			<tr key={element.id}>
				<td className="text-center">{element.id}</td>
				<td>{element.description}</td>
				<td className="text-end">${element.rate}</td>
				<td className="text-end">{element.quantity}</td>
				<td className="text-end">${element.price}</td>
			</tr>
		</>
	);
};

export const FormTable = ({ element, editItem }) => {
	const dispatch = useDispatch();
	return (
		<>
			<tr key={element.id}>
				<td className="text-center">{element.id}</td>
				<td>{element.description}</td>
				<td className="text-end">${element.rate}</td>
				<td className="text-end">{element.quantity}</td>
				<td className="text-end">${element.price}</td>
				<td className="text-center p-0">
					<button type="button" className="btn btn-sm btn-primary mx-1" onClick={() => editItem(element.id, element.description, element.rate, element.quantity)} data-toggle="tooltip" data-placement="right" title="Edit">
						<EditIcon fontSize="small" />
					</button>
					<button type="button" className="btn btn-sm btn-danger mx-1" onClick={() => dispatch(deleteItem(element.id))} data-toggle="tooltip" data-placement="right" title="Delete">
						<HighlightOffIcon fontSize="small" />
					</button>
				</td>
			</tr>
		</>
	);
};

export const TopTable = ({ invoice, date, due }) => {
	return (
		<>
			<div className="col-md-6 col-12 my-2">
				<table className="invoice-top-table">
					<tr>
						<td className="table-head">Invoice Number</td>
						<td>{invoice}</td>
					</tr>
					<tr>
						<td className="table-head">Date</td>
						<td>{date}</td>
					</tr>
					<tr>
						<td className="table-head">Amount Due</td>
						<td> {due < 0 ? `$ -${-1 * due}` : `$${due}`}</td>
					</tr>
				</table>
			</div>
		</>
	);
};

export const BottomTable = ({ totalPrice, paid, setPaid }) => {
	const dispatch = useDispatch();
	return (
		<>
			<div className="col-12 mt-3">
				<table className="invoice-end-table">
					<tr>
						<td className="table-head">Total</td>
						<td className="text-end">${totalPrice}</td>
					</tr>
					<tr>
						<td className="table-head">Amount Paid</td>
						<td className="text-end p-0 w-100 d-flex">
							<input
								type="text"
								className="amount-paid-input text-end"
								name="paid"
								value={paid}
								onChange={(e) => {
									setPaid(e.target.value);
									dispatch(due(e.target.value));
								}}
							/>
						</td>
					</tr>
					<tr>
						<td className="table-head">Balance Due</td>
						<td className="text-end">$ {totalPrice - paid}</td>
					</tr>
				</table>
			</div>
		</>
	);
};

export const MiddleTable = ({ itemsList }) => {
	return (
		<>
			<div className="col-12">
				{itemsList.length > 0 ? (
					<table className="invoice-bottom-table">
						<tr>
							<td className="table-head text-center">Item</td>
							<td className="table-head text-center td-description">Description</td>
							<td className="table-head text-center">Rate</td>
							<td className="table-head text-center">Quantity</td>
							<td className="table-head text-center">Price</td>
							<td className="table-head text-center td-action">Actions</td>
						</tr>
						{itemsList}
					</table>
				) : (
					<div className="addBorder">
						<h3 className="text-center my-3">NO DATA AVAILABLE</h3>
					</div>
				)}
			</div>
		</>
	);
};
