import React from "react";

const Footer = () => {
    return (
        <>
            <div className="copyright">
                <h2>&copy; 2021 - AutomationsHub.com</h2>
            </div>
        </>
    );
};

export default Footer;
