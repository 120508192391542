import React from "react";

export const AddForm = ({ item, InputEvent, edit, submitForm, updateForm }) => {
    return (
        <>
            <div className="col-12 mt-3">
                <h3 className="text-center">Add Items</h3>

                <form class="row">
                    <div class="form-group col-md-3 col-12 my-2">
                        <input
                            type="text"
                            class="form-control"
                            id="desc"
                            placeholder="Description"
                            name="description"
                            value={item.description}
                            onChange={InputEvent}
                        />
                    </div>
                    <div class="form-group col-md-3 col-12 my-2">
                        <input
                            type="number"
                            class="form-control"
                            id="rate"
                            placeholder="Rate"
                            name="rate"
                            value={item.rate}
                            onChange={InputEvent}
                        />
                    </div>
                    <div class="form-group col-md-3 col-12 my-2">
                        <input
                            type="number"
                            class="form-control"
                            id="quantity"
                            placeholder="Quantity"
                            name="quantity"
                            value={item.quantity}
                            onChange={InputEvent}
                        />
                    </div>
                    <div class="form-group col-md-3 col-12 my-2">
                        {!edit ? (
                            <button
                                onClick={submitForm}
                                type="submit"
                                class="btn form-btn form-group col-md-3 col-12 w-100"
                            >
                                Add Item
                            </button>
                        ) : (
                            <button
                                onClick={updateForm}
                                type="submit"
                                class="btn form-btn form-group col-md-3 col-12 w-100"
                            >
                                Update Item
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
};

export const CustomerForm = ({ customer, InputEventCustomer }) => {
    return (
        <>
            <div className="col-md-6 col-12">
                <form>
                    <div className="form-group my-2">
                        <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Customer Name"
                            value={customer.name}
                            onChange={InputEventCustomer}
                        />
                    </div>
                    <div className="form-group my-2">
                        <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Customer Email"
                            value={customer.email}
                            onChange={InputEventCustomer}
                        />
                    </div>
                    <div className="form-group my-2">
                        <input
                            type="text"
                            name="address"
                            className="form-control"
                            placeholder="Customer Address"
                            value={customer.address}
                            onChange={InputEventCustomer}
                        />
                    </div>
                    <div className="form-group my-2">
                        <input
                            type="company"
                            name="company"
                            className="form-control"
                            placeholder="Customer's Company"
                            value={customer.company}
                            onChange={InputEventCustomer}
                        />
                    </div>
                </form>
            </div>
        </>
    );
};
